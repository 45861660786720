import request from '@/service/request'

/* 分页根据物理地址获取锁房卡指令记录信息 （也可以用于筛选已绑定该锁的学生）
* regionIds {Array} regionId=安装了电表的宿舍
* */
export const pageLockCardByCodeApi = (params) => {
  return request.post('/app/kxb/pageLockCardByCode', params)
}

/* 获取设房卡指令（设置类）发房卡
* params
* */
export const activateDormCardApi = (params) => {
  return request.post('/app/kxb/roomNumCard', params)
}

/* 获取设房卡指令（设置类）启用/禁用房卡
* @params {number} enableFlag
* @params {string} id
* */
export const enableDormCardApi = (params) => {
  return request.post('/app/kxb/loseAndRecoverCard', params)
}

/* 获取校区卡/总控卡（设置类） 发总控卡/校区卡
* params {string} aalockId 锁ID
* */
export const activateCardForCampusApi = (params) => {
  return request.post('/app/kxb/overallControlCardByCampusId', params)
}

/* 获取设楼卡（设置类） 发楼卡
* params {string} aalockId 锁ID
* */
export const activateCardForBuildingApi = (params) => {
  return request.post('/app/kxb/saveBuildCard', params)
}

/* 获取设层卡（设置类） 发层卡
* params {string} aalockId 锁ID
* */
export const activateCardForFloorApi = (params) => {
  return request.post('/app/kxb/saveFloorCard', params)
}

/* 获取设授权卡（设置类） 发授权卡
* params {string} aalockId 锁ID
* */
export const activateCardForAccessApi = (params) => {
  return request.post('/app/kxb/authCardInst', params)
}

/* 获取设组卡（设置类） 发组卡
* params {string} aalockId 锁ID
* */
export const activateCardForTeamApi = (params) => {
  return request.post('/app/kxb/setTeamCard', params)
}

/* 发卡 (绑定学生/教师)
* params {string} aalockId 锁ID
* params {string} userId 学生的id
* */
export const bindDormCardByUserIdApi = (params) => {
  return request.post('/app/kxb/saveRoomCard', params)
}

/* 退卡 (整个宿舍的卡都会无效)
* params {string} aalockId 锁ID
* */
export const clearDormCardApi = (params) => {
  return request.post('/app/kxb/checkOutCard', params)
}
