import request from '@/service/request'

/* 补卡记录
* */
export const pageReissueRecordApi = (params) => {
  return request.post('/app/lockCardSupplement/page', params)
}
/* 补卡记录 退款
* @params {string} orderNo 订单号
* */
export const refundApi = (params) => {
  return request.post('/app/lockCardSupplement/refund', params)
}
