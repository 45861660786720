<template>
  <el-table :data="tableData" style="width: 100%" border stripe>
    <el-table-column prop="orderNo" label="订单编号" width="260" align="center">
      <template v-slot="{row}">
        {{ row.orderNo }}
        <!--        <el-link v-if="row.payUrl" :href="row.payUrl" title="支付链接" target="_blank" type="primary">-->
        <!--          <i class="el-icon-link"></i></el-link>-->
      </template>
    </el-table-column>
    <el-table-column prop="status" label="支付状态" width="110" align="center">
      <template v-slot="{row}">
        <ElTag v-if="payStatusMap[row.status]" :type="payStatusMap[row.status].type">
          {{ payStatusMap[row.status].label }}
        </ElTag>
      </template>
    </el-table-column>
    <el-table-column prop="payTime" label="支付时间" width="190" align="center" />
    <el-table-column prop="enterFlag" label="确认状态" width="100" align="center">
      <template v-slot="{row}">
        <el-tag :type="+row.enterFlag?'success':'info'">{{ +row.enterFlag ? '已确认' : '未确认' }}</el-tag>
      </template>
    </el-table-column>
    <el-table-column prop="userType" label="用户类型" width="100" align="center">
      <template v-slot="{row}">
        {{ row.userType === 2 ? '学生' : row.userType === 3 ? '教职工' : '' }}
      </template>
    </el-table-column>
    <el-table-column prop="teaName" label="姓名" width="140">
      <template v-slot="{row}">
        {{ row.teaName || row.stuName }}
      </template>
    </el-table-column>
    <el-table-column prop="teaNo" label="教工号/学号" width="180" align="center">
      <template v-slot="{row}">
        {{ row.teaNo || row.stuNo }}
      </template>
    </el-table-column>
    <el-table-column prop="teaSexType" label="性别" width="80" align="center">
      <template v-slot="{row}">
        <span v-if="row.teaSexType">
        {{ sexTypeMap[row.teaSexType].label }}
          </span>
        <span v-else-if="row.sexType">
        {{ sexTypeMap[row.sexType].label }}
          </span>
      </template>
    </el-table-column>
    <el-table-column label="宿舍号" min-width="300">
      <template v-slot="{row}">
        <span v-if="row.dormitoryName">{{ row.dormitoryName }}</span>
        <span v-if="row.floor">第{{ row.floor }}层</span>
        <span v-if="row.roomName">{{ row.roomName }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="teaCollegeName" label="学院（班级）/部门" min-width="240">
      <template v-slot="{row}">
        {{ row.collegeName || row.teaDeptName || row.teaCollegeName }}
        <template v-if="row.className">
          {{ row.majorName || '' }}{{ row.className }}
        </template>
      </template>
    </el-table-column>
    <el-table-column prop="teaPhone" label="教师手机" width="160" align="center" />
    <el-table-column prop="fixedUserName" label="发卡人" width="160" />
    <el-table-column label="操作" fixed="right">
      <template v-slot="{row}">
        <el-button v-if="row.status!==4" type="primary" size="mini" @click="$emit('refund',row)">退款</el-button>
      </template>
    </el-table-column>
  </el-table>
</template>

<script>
export default {
  name: 'ViewAll',
  props: {
    tableData: {
      type: Array,
      default: () => ([])
    },
    payStatusMap: {
      type: Object,
      default: () => ({})
    },
    sexTypeMap: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
