<!--补卡记录-->
<template>
  <table-view
    class="reissue-record"
    :total="total"
    v-loading="loading"
    :query-info.sync="queryInfo"
    @reload-table="renderTable"
  >
    <template #header>
      <div class="mb-1">
        <el-radio-group v-model="queryInfo.userType" size="small" @change="renderTable">
          <el-radio-button label="">全部</el-radio-button>
          <el-radio-button :label="2">学生</el-radio-button>
          <el-radio-button :label="3">教职工</el-radio-button>
        </el-radio-group>
        <el-tag class="fr" size="large" type="success">总金额：{{ totalFee }}元</el-tag>
      </div>
      <view-search-form
        ref="searchFormRef"
        label-width="55px"
        :queryInfo.sync="queryInfo"
        :tool-list="['college', 'major', 'class']"
        @on-search="renderTable(1)">
        <el-form-item label="开始时间" prop="startTime" label-width="80px">
          <el-date-picker
            v-model="dateRange"
            type="datetimerange"
            :picker-options="pickerOptions"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            align="right"
            size="small"
            value-format="yyyy-MM-dd HH:mm:00"
            format="yyyy-MM-dd HH:mm"
            @change="renderTable(1)" />
        </el-form-item>
        <el-form-item label="楼栋" prop="dormitoryId" label-width="55px">
          <el-select
            size="small"
            v-model="queryInfo.dormitoryId"
            @change="changeBuild"
            clearable
            filterable>
            <el-option
              v-for="{dormitoryName,id} in buildList"
              :label="dormitoryName"
              :value="id"
              :key="id" />
          </el-select>
        </el-form-item>
        <el-form-item label="楼层" label-width="55px">
          <el-select
            size="small"
            v-model="queryInfo.floorNum"
            clearable
            filterable
            @change="getDormRoomList">
            <el-option
              v-for="num in floorList"
              :label="`第${num}层`"
              :value="num"
              :key="num" />
          </el-select>
        </el-form-item>
        <el-form-item label="宿舍">
          <el-select size="small" v-model="queryInfo.dormitoryRoomId" clearable filterable>
            <el-option
              v-for="{roomName,id} in roomList"
              :label=" roomName"
              :value="id"
              :key="id" />
          </el-select>
        </el-form-item>
        <el-form-item label="支付状态" label-width="90px">
          <el-select size="small" v-model="queryInfo.statusList" clearable filterable>
            <el-option
              v-for="{label,value} in statusList"
              :label="label"
              :value="value"
              :key="label" />
          </el-select>
        </el-form-item>
      </view-search-form>
    </template>
    <!-- body -->
    <!-- 学生 table  要等数据加载完毕之后才能 加载table,否则格子对不齐，因此通过visible控制 -->
    <template v-if="!loading">
      <ViewAll v-if="!queryInfo.userType" :sex-type-map="sexTypeMap" :table-data="tableData"
               :pay-status-map="payStatusMap" @refund="handleRefund" />
      <ViewStudent v-if="queryInfo.userType===2" :sex-type-map="sexTypeMap" :table-data="tableData"
                   :pay-status-map="payStatusMap" @refund="handleRefund" />
      <!--  教师 table  -->
      <ViewTeacher v-if="queryInfo.userType===3" :sex-type-map="sexTypeMap" :table-data="tableData"
                   :pay-status-map="payStatusMap" @refund="handleRefund" />
    </template>
  </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import nfcCard from '@/vue/mixins/nfc-card.js'
import store from '@/store/index'
import { pageReissueRecordApi, refundApi } from '@/api/dorm/reissue-record-api'
import ViewTeacher from '@/views/pages/dormManage/aalock/reissue-record/components/ViewTeacher.vue'
import ViewStudent from '@/views/pages/dormManage/aalock/reissue-record/components/ViewStudent.vue'
import ViewAll from '@/views/pages/dormManage/aalock/reissue-record/components/ViewAll.vue'

export default {
  name: 'reissueRecord',
  components: { ViewAll, ViewStudent, ViewTeacher },
  mixins: [tableView, nfcCard],
  data() {
    return {
      // el-select不支持单选的数组格式，因此需要在renderTable时转数组
      statusList: [
        { value: '[1,0]', label: '待支付', type: null },
        { value: '[2,3]', label: '已支付', type: 'success' },
        { value: '[4]', label: '已退款', type: 'info' }
      ],
      // 简化版支付状态
      payStatusMap: {
        0: { value: 0, label: '创建', type: null },
        1: { value: 1, label: '待支付', type: null },
        2: { value: 2, label: '已支付', type: 'success' },
        3: { value: 3, label: '已完成', type: 'success' },
        4: { value: 4, label: '已退款', type: 'info' }
      },
      queryInfo: {
        userType: '',
        classesId: null,
        collegeId: null,
        dormitoryId: null,
        dormitoryRoomId: null,
        endTime: null,
        enterFlag: null,
        floorNum: null,
        keyword: null,
        majorId: null,
        pageNum: 1,
        pageSize: 15,
        startTime: null,
        status: null,
        statusList: '[2,3]',
        userId: null
      },
      totalFee: 0,
      isAdmin: store.state.login.isAdmin,
      buildList: [], // 楼栋
      floorList: 0, // 楼层
      roomList: [], // 宿舍
      tableData: [], // 宿舍
      loading: false,
      dateRange: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      }
    }
  },
  watch: {
    'queryInfo.collegeId': function(newVal) {
      // 监控 collegeId变化,及时清空 子级的list
      if (!newVal) {
        this.clearList(this.classList)
        this.queryInfo.classId = null
        this.queryInfo.collegeId = null
      }
    }
  },
  created() {
    this.sexTypeMap = this.$store.state.systemParam.sexTypeMap
  },
  async mounted() {
    await Promise.all([
      this.getDormBuild(),
      this.renderTable(1)
    ])
  },
  methods: {
    async renderTable(pageNum) {
      this.loading = true
      const userType = this.queryInfo.userType || null
      const statusList = JSON.parse(this.queryInfo.statusList || '[]')
      if (this.dateRange) {
        this.queryInfo.startTime = this.dateRange[0]
        this.queryInfo.endTime = this.dateRange[1]
      }
      if (pageNum) {
        this.queryInfo.pageNum = pageNum
      }
      while (this.tableData.length) this.tableData.pop()
      try {
        const { data } = await pageReissueRecordApi({ ...this.queryInfo, userType, statusList })
        this.tableData = data.page.list
        this.total = data.page.total
        this.totalFee = data.totalFee
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
      }
    },
    // 退款
    handleRefund(row) {
      if (!row.orderNo) {
        this.$message.error('订单不存在，无法进行退款操作！')
        return
      }
      console.log(row)
      const _name = row.stuName || row.teaName || '佚名'
      const _No = row.stuNo ? `学号：${row.stuNo}` : `教工号：${row.teaNo}`
      this.$confirm(`是否确认为${_name}【${_No}】（订单号：${row.orderNo}）办理退款？`, '退款确认').then(async () => {
        try {
          await refundApi({ orderNo: row.orderNo })
          this.$message.success('操作成功，款项已原路退回。')
          await this.renderTable()
        } catch (e) {
          console.error(e)
        }
      })
    },
    // 获取楼栋
    async getDormBuild() {
      // 1 获取宿舍楼list
      try {
        const res = await this.$http.getDormBuildQuery({})
        this.buildList = res.data
        // 3.由于数据量太大,所以不建议初始化时就加载整个学校的宿舍数据,除非有必要
      } catch (e) {
        console.error(e)
      }
    },
    // 选择楼栋
    changeBuild(id) {
      this.clearList(this.roomList)
      this.queryInfo.floorNum = null
      this.queryInfo.dormitoryRoomId = null
      // 监控 楼栋dormitoryId变化,及时清空 子级的list
      if (id) {
        this.floorList = (this.buildList.filter((item) => item.id === id)[0].floorAmount) || 0
      } else {
        this.floorList = 0
      }
    },
    // 获取宿舍列表
    getDormRoomList() {
      this.clearList(this.roomList)
      this.queryInfo.roomId = null
      this.$http
        .getDormRoomQuery({
          dormitoryId: this.queryInfo.dormitoryId,
          floorNum: this.queryInfo.floorNum
        })
        .then((res) => {
          this.roomList = res.data
        })
    },
    // 清空数组
    clearList(arr) {
      if (arr) {
        while (arr.length) arr.pop()
      }
    },
    async handleUserType() {
      await this.renderTable(1)
      this.showType = this.queryInfo.userType
    }
  }
}
</script>
