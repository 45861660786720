import { enableDormCardApi } from '@/api/dorm/lock-api'

export default {
  data() {
    return {
      rfidreader: null,
      KeyString: 'FFFFFFFFFFFF',
      sendData: '',
      block: 8,
      message: '发卡成功！',
      enableRow: {}, // 存储启用、禁用的行数据
      enableFlag: false // 是否禁用/启用 请求，是的话写入失败时需要把enableFlag改回原来的
    }
  },
  mounted() {
    this.installNfc().then()
  },
  methods: {

    // 禁用 / 启用 房卡
    async handleEnable(row, flag) {
      try {
        const res = await enableDormCardApi({
          id: row.id,
          enableFlag: flag
        })
        return res.data
      } catch (e) {
        console.error(e)
      }
    },
    readNfc(val) {
      if (val) {
        this.sendData = val
      }
      this.rfidreader.KeyMode = 0 // 密钥类型 0为A密钥 1为B密钥
      this.rfidreader.KeyStringMode = 0 // 密钥字符串类型 0为16进制 1为普通字符
      this.rfidreader.KeyString = this.KeyString // 密钥字符串
      // rfidreader.KeyString='FFFFFFFFFFFF'; // 密钥字符串
      this.rfidreader.Repeat = 0 // 重复次数  0为1次 1为重复
      this.rfidreader.M1ReadSector(this.block, 0)
    },
    // 写入卡数据
    writeNfc() {
      this.rfidreader.KeyMode = 0 // 密钥类型 0为A密钥 1为B密钥
      this.rfidreader.KeyStringMode = 0 // 密钥字符串类型 0为16进制 1为普通字符
      this.rfidreader.KeyString = this.KeyString // 密钥字符串
      this.rfidreader.Repeat = 0 // 重复次数  0为1次 1为重复
      this.rfidreader.M1WriteSector(this.block, this.sendData, 0)
    },
    // 修改扇区
    modifySectorKey() {
      this.rfidreader.KeyMode = 0
      this.rfidreader.KeyStringMode = 0
      this.rfidreader.KeyString = 'FFFFFFFFFFFF'
      const d = 'B0C2A3A6CBF8FF078069FFFFFFFFFFFF'
      const index = (this.block * 4 + 3)
      console.log('修改扇区了---', index)
      this.rfidreader.M1WriteBlock(index, d, 0)
    },
    // 初始化读卡(检测是否以安装)
    async installNfc() {
      try {
        this.rfidreader = YOWORFIDReader.createNew()
      } catch (error) {
        return this.$confirm('<div><p>当前浏览器环境未安装发卡模块，请安装发卡模块后重开浏览器使用发卡功能。</p><b style="color:var(--color-danger)">请注意，安装NFC插件后会导致当前浏览器的书签丢失，只需要在左下角的开始栏重新打开浏览器即可恢复书签。</b><a href="http://www.youwokeji.com.cn/CloudReader/YOWORFIDReaderCloudForWeb.exe" target="_blank">点击下载发卡模块</a></div>',
          '温馨提示',
          { dangerouslyUseHTMLString: true }
        )
      }

      if (!this.rfidreader.TryConnect()) {
        this.$message('当前浏览器不支持，请更换浏览器后重试！')
      }
      await this.rfidreader.onResult(async (resultdata) => {
          switch (resultdata.FunctionID) {
            case 14: //
              console.log('code 14')
              break
            case 7: // 读取
              if (resultdata.Result === -6) {
                if (this.KeyString === 'B0C2A3A6CBF8') {
                  this.$message.error('该扇区为未知密钥扇区，请更换扇区重试')
                  return
                }
                this.KeyString = 'B0C2A3A6CBF8'
                this.readNfc()
              } else if (resultdata.Result === -3) {
                this.$message.info('请检测是否已放置卡')
              } else { // 读卡成功
                if (this.KeyString === 'FFFFFFFFFFFF') {
                  this.modifySectorKey()
                } else {
                  this.writeNfc()
                }
              }
              break
            case 6:
              if (resultdata.Result > 0) {
                this.KeyString = 'B0C2A3A6CBF8'
                this.writeNfc()
              } else {
                this.$message.error('修改密钥失败')
                console.log('修改密钥失败' + resultdata.Result)
                if (this.enableFlag) {
                  await this.handleEnable(this.enableRow, this.enableRow.enableFlag)
                  this.enableFlag = false
                  this.enableRow = {}
                  await this.renderTable()
                }
              }
              break
            case 8:
              if (resultdata.Result > 0) {
                this.$message.success(this.message)
                this.message = '发卡成功！'
                this.KeyString = 'FFFFFFFFFFFF'
                console.log('写入成功，写入16进制数据：' + resultdata.strData)
              } else {
                this.$message.error('写入失败')
                console.log('写入失败：' + resultdata.Result)
              }
              break
          }
        }
      )
    }
  }
}
